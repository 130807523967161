import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { CalloutBanner, Image, PageSeparator } from '../../components';
import SequenceGraphic from '../../assets/images/graphic-sequencing-scenarios.svg';
import SequenceGraphicMobile from '../../assets/images/graphic-sequencing-scenarios.svg';

// SEO INFORMATION
const pageMeta = {
  title: 'Treatment Sequencing for WELIREG™ (belzutifan)',
  keywords: 'treatment sequencing for welireg in advanced rcc patients',
  description:  'Health care professionals can review treatment sequencing for WELIREG™ (belzutifan) and hypothetical treatment scenarios in certain patients.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/treatment-considerations/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/treatment-considerations/","@type":"MedicalWebPage","name":"Treatment Sequencing for WELIREG™ (belzutifan)","description":"WELIREG may be considered as early as the second line for your appropriate patients with advanced RCC following progression with both anti–PD-1/L1 and VGEF-TKI therapies"}`    
  ]
}

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const TreatmentImage = styled(Image)`

  img {
    margin-left: 0;
  }

  [class*='Image__TitleContainer'] {
    margin-bottom: 24px;
  }
`;

const calloutBanner = {
  title: "Explore the efficacy data from LITESPARK-005",
  bcbContinual: true,
  links: [
    {
      label: "See the Data",
      url: "/advanced-renal-cell-carcinoma/efficacy",
    }
  ]
}

const imageData= {
  footnotes: [
    {
      label: 'a',
      text: 'The above graphic demonstrates earliest possible clinical use of WELIREG. These scenarios do not represent all possible treatment sequencing options available for  patients with advanced RCC.',
    },
  ],
  definitions: <>PD&#8288;-&#8288;1/L1 = programmed death receptor-1 (PD&#8288;-&#8288;1)/programmed death-ligand 1 (PD&#8288;-&#8288;L1); VEGF&#8288;-&#8288;TKI = vascular endothelial growth factor tyrosine kinase inhibitor; 2L = second line; 3L = third line; CTLA-4 = cytotoxic T-lymphocyte–associated antigen 4.</>
};

const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/advanced-renal-cell-carcinoma/treatment-considerations/" />
      </PageSeo>

      <PageTitle unbranded>
        Treatment Sequencing for WELIREG™ (belzutifan)
      </PageTitle>

      <StandardSection>
        <PageSeparator topPadding='28px' />
      </StandardSection>

      <StandardSection>
        <TreatmentImage 
            title="WELIREG may be considered as early as the second line for your appropriate patients with advanced RCC following progression with both anti–PD1/L1 and VEGF&#8288;-&#8288;TKIs therapies"
            graphTitle="Select hypothetical treatment scenarios<sup>a</sup>"
            imgPath={SequenceGraphic}
            mobImgPath={SequenceGraphicMobile}
            imgAlt="Select Hypothetical Treatment Scenarios for WELIREGTM (belzutifan) for Patients With Advanced RCC Following Progression With Both PD⁠-⁠1/L1 and VEGF⁠-⁠TKI Therapies, in Sequence or in Combination"
            imageScroll={true}
            {...imageData} 
          />
      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
      </StandardSection>

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default IndexPage;
